import React from "react"
import SEO from "../components/seo"

export default function Home() {
  return <div className="container px-5 my-10 lg:mx-auto">
    <SEO />
    <div className="lg:w-2/3 xl:w-1/2 mx-auto">
      <div className="text-center">
        <h1 className="inline-block mx-auto"><img src="https://upload.wikimedia.org/wikipedia/fr/thumb/5/5c/Logo_AS_Saint-Ouen-l%27Aum%C3%B4ne.svg/langfr-260px-Logo_AS_Saint-Ouen-l%27Aum%C3%B4ne.svg.png" alt="AS Saint-Ouen-l'Aumône Football" width="150" /></h1>
      </div>
      <div className="mt-8">
        <h2 className="inline-block bg-white px-4 py-1 text-black mb-3">Nouveau site officiel</h2>
        <a href="https://assoa-football.footeo.com/" rel="sponsored" className="block">https://assoa-football.footeo.com/ 👈👈👈</a>
      </div>

      <div className="mt-8">
        <h2 className="inline-block bg-white px-4 py-1 text-black mb-3">Qui sommes-nous ?</h2>
        <p>Section football de l'ASSOA Omnisports parrainée par la ville de <a href="https://www.ville-saintouenlaumone.fr/" rel="external">Saint Ouen l'Aumône</a> dans le Val-d'Oise (Ile-de-France), l'ASSOA Football a été créé en 1946.<br />
        Le club amateur voit actuellement son équipe fanion (séniors) nouvellement entraînée par Madny Fofana (ancien coach des U19 R2) évoluer en Régional 1.</p>
      </div>

      <div className="mt-8">
        <h2 className="inline-block bg-white px-4 py-1 text-black mb-3">Comment rejoindre l'AS Saint Ouen l'Aumône (ASSOA) ?</h2>
        <p className="mb-3">L'ASSOA Football organise tous les ans des <a href="https://www.wannateam.com/club/as-saint-ouen-l-aumone/" rel="external">détections et des tests de football</a> auxquels les footballeurs sont conviés à participer entre les mois d'avril et juin. Vous participerez à des tests techniques et des confrontations sous l'oeil attentif des coachs, éducateurs et dirigeants du club. C'est une bonne occasion pour rejoindre le club du Val-d'Oise pour la saison 2021-2022.</p>
        <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffootballassoa%2Fvideos%2F3181008075275527%2F&show_text=0&width=400" width="400" height="400" scrolling="no" frameBorder="0" allowTransparency="true" allowFullScreen="true"></iframe>
      </div>

      <div className="mt-8">
        <h2 className="inline-block bg-white px-4 py-1 text-black mb-3">Quels footballeurs professionnels ont été formés par l'ASSOA ?</h2>
        <p className="mb-3">Quelques noms emblématiques ont foulé les terrains de l'ASSOA et ont retiré de cette expérience des apprentissages leur permettant d'aller plus loin dans leur carrière de footballeur. Parmi eux, vous connaissez surement :</p>
        <ul className="mb-3">
          <li>▪ Pierre Issa (OM)</li>
          <li>▪ Grégory Proment (FC Metz)</li>
          <li>▪ Pierre Ducrocq (PSG)</li>
          <li>▪ Pierrick Capelle (SC Angers)</li>
          <li>▪ Jimmy Hebert (SM Caen Calvados Basse-Normandie)</li>
          <li>▪ Axel Ngando (SC Bastia)</li>
          <li>▪ Éric Rabésandratana (PSG)</li>
        </ul>
        <p>D'autres footballeurs remarquables sont passés par Saint Ouen l'Aumône, cette liste n'a absolument rien d'exhaustive...</p>
      </div>

      <div className="mt-8">
        <h2 className="inline-block bg-white px-4 py-1 text-black mb-3">Quelle est l'adresse du club ?</h2>
        <p>Boulevard Ducher<br />
        95310 Saint-Ouen-L'Aumone</p>
      </div>

      <div className="mt-8">
        <h2 className="inline-block bg-white px-4 py-1 text-black mb-3">Liens utiles</h2>
        <ul>
          <li>▪ <a href="https://www.fff.fr/" rel="nofollow">Fédération Française de Football</a></li>
          <li>▪ <a href="https://www.paris-idf.fff.fr/" rel="external">Ligue de Paris Ile de France de Football</a></li>
          <li>▪ <a href="https://www.district-foot95.fff.fr/" rel="nofollow">District du Val D'Oise de Football</a></li>
        </ul>
      </div>
    </div>
  </div>
}